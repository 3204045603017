import {
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
  //colorVariants,
} from '@backstage/theme';

export const kubriXTheme = createUnifiedTheme({
  palette: {
    ...palettes.light,
    primary: {
      main: '#0f7abf',  // links
    },
    secondary: {
      main: '#565a6e',  // Example Teal Color
    },
    error: {
      main: '#8c4351',
    },
    warning: {
      main: '#8f5e15',
    },
    success: {
      main: '#06B6D4',
    },
    info: {
      main: '#06B6D4',
    },
    navigation: {
      ...palettes.light.navigation,
      background: 'linear-gradient(180deg, #3e8cc8 0%, #3187c5 10%)', // Background sidebar  #378BC7 work #3187c5
      color: '#f4f4f4', // text color sidebar
      indicator: '#6152c7',
      selectedColor: '#44546A',
      navItem: {
        hoverBackground: '#35abe2', // Adjusted hover
      },
      submenu: {
        background: 'linear-gradient(180deg, #3e8cc8 0%, #3187c5 10%)', // light blue
      },
    },
    text: {
      primary: '#44546A', // Card Text
    },
    background: {
      default: '#ffffff', // Background Page
      paper: '#f4f4f4', // Card Color // Light grey  

    },
  },
  //https://github.com/backstage/backstage/blob/39f0543e6d7fef560d8cba62f95739d9ce523427/packages/theme/src/base/pageTheme.ts
  pageTheme: {
  //  home: genPageTheme({ colors: colorVariants.purpleSky, shape: shapes.round }), //banner color
    home: genPageTheme({ colors: ['#0f7abf', '#cc02fe'], shape: shapes.round }), //banner color
    documentation: genPageTheme({ 
      colors: ['#0f7abf', '#cc02fe'],
      shape: shapes.round,
    }),
    project: genPageTheme({
      colors: ['#0f7abf', '#cc02fe'],
      shape: shapes.round,
    }),
    tool: genPageTheme({ 
      colors: ['#0f7abf', '#cc02fe'], 
      shape: shapes.round }),
    library: genPageTheme({
      colors: ['#0f7abf', '#cc02fe'],
      shape: shapes.round,
    }),
    technique: genPageTheme({ colors: ['#0f7abf', '#cc02fe'], shape: shapes.round }),
    other: genPageTheme({ colors: ['#0f7abf', '#cc02fe'], shape: shapes.round }),
    apis: genPageTheme({ colors: ['#0f7abf', '#cc02fe'], shape: shapes.round }),
    card: genPageTheme({ colors: ['#0f7abf', '#cc02fe'], shape: shapes.round }),
    template: genPageTheme({ colors: ['#0f7abf', '#cc02fe'], shape: shapes.round }),

  },
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Roboto Mono, sans-serif',
    h1: {
      fontSize: 54,
      fontWeight: 700,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  defaultPageTheme: 'home',
  components: {
    BackstageInfoCard: {
      styleOverrides: {
        
      }
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecorationLine: 'none'
        }
      }
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: 'inherit',
          textTransform: 'none',
        },
      },
    },

    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {
          '&> :first-child': {
            borderBottom: '1px solidrgb(45, 17, 17)',
            boxShadow: 'none',
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important',
          },
        },
      },
    },
    CatalogReactUserListPicker: {
      styleOverrides: {
        title: {
          textTransform: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&[aria-expanded=true]': {
            backgroundColor: '#26385A',
            color: '#FFFFFF',
          },
          '&[aria-expanded=true] path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(9,30,69,0.54)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&[aria-expanded]': {
            backgroundColor: '#26385A',
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: '#FFFFFF',
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    },
  },
  });