import React from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  HarborPage,
  HarborWidget,
  isHarborAvailable,
} from '@digitalist-open-cloud/backstage-plugin-harbor'
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import {
  EntityGithubInsightsContent,
  EntityGithubInsightsComplianceCard,
  isGithubInsightsAvailable,
} from '@roadiehq/backstage-plugin-github-insights';
import { 
  isTechDocsAvailable,
  EntityTechdocsContent 
} from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
  Entity
} from '@backstage/catalog-model';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { 
  EntityKubernetesContent,
  isKubernetesAvailable,
 } from '@backstage/plugin-kubernetes';
import { EntityGithubPullRequestsContent } from '@roadiehq/backstage-plugin-github-pull-requests';
import {
  GithubIssuesPage,
} from '@backstage-community/plugin-github-issues';
import { AnnouncementsCard } from '@k-phoen/backstage-plugin-announcements';
import {
  EntityGrafanaDashboardsCard, 
  EntityOverviewDashboardViewer,
  isOverviewDashboardAvailable,
} from '@k-phoen/backstage-plugin-grafana';
import { EntityAdrContent, isAdrAvailable } from '@backstage-community/plugin-adr';
// import { K8sGPTPage } from '@suxess-it/backstage-plugin-k8sgpt';
import { 
  BackstagePluginKubecostPage,
  isKubecostAvailable,
} from '@suxess-it/backstage-plugin-kubecost';
import { EntityVaultCard, isVaultAvailable } from '@backstage-community/plugin-vault';
import { EntityJiraOverviewCard, isJiraAvailable } from '@roadiehq/backstage-plugin-jira';
import {
  EntityIFrameCard
} from '@roadiehq/backstage-plugin-iframe';
import { CrossplaneAllResourcesTable, CrossplaneResourceGraph, isCrossplaneAvailable, CrossplaneOverviewCard } from '@terasky/backstage-plugin-crossplane-resources-frontend';

import { EntityScaffolderContent } from '@terasky/backstage-plugin-entity-scaffolder-content';
import { KyvernoPolicyReportsTable, KyvernoOverviewCard } from '@terasky/backstage-plugin-kyverno-policy-reports';

import {
  EntityArgoCDHistoryCard,
  EntityArgoCDOverviewCard,
  isArgocdAvailable,
} from '@roadiehq/backstage-plugin-argo-cd';

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
//  <EntitySwitch>
//    <EntitySwitch.Case if={isGithubActionsAvailable}>
//      <EntityGithubActionsContent />
//    </EntitySwitch.Case>

<EntitySwitch>

<EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
        <Grid item sm={12}>
          <EntityArgoCDHistoryCard />
        </Grid>
      </EntitySwitch.Case>


    {/* <EntitySwitch.Case if={e => Boolean(isArgocdConfigured(e))}>
      <Grid item sm={12}>
        <ArgocdDeploymentLifecycle />
    </Grid>
    </EntitySwitch.Case> */}

    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>


    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const crossplaneOverviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={8} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
    <Grid item md={6}>
      <CrossplaneOverviewCard />
    </Grid>
  </Grid>
);
//terasky
const crossplaneEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {crossplaneOverviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route if={isKubernetesAvailable} path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent />
    </EntityLayout.Route>
    <EntityLayout.Route if={isCrossplaneAvailable} path="/crossplane-resources" title="Crossplane Resources">
      <CrossplaneAllResourcesTable />
    </EntityLayout.Route>
    <EntityLayout.Route if={isCrossplaneAvailable} path="/crossplane-graph" title="Crossplane Graph">
      <CrossplaneResourceGraph />
    </EntityLayout.Route>
  </EntityLayout>
);

const grafanaContent = (
  <EntityOverviewDashboardViewer />
)


/*
const iframeProps = (): {iframe: {src: string}, {title: string}}  => {
  const configApi = useApi(configApiRef);
  const { entity } = useEntity();
  const kargoHostname = configApi.getOptionalStringArray('kargo.hostname');
  const kargoProject = entity.metadata.annotations?.["kargo-project"];

  return ({
  iframe:
    {
      src: "https://"+{kargoHostname}+"/project/"+{kargoProject}
    }
  ,
  title: "Kargo"
  })
}
*/


const iframeKargoProps = {
  srcFromAnnotation: "kargo-project",
  title: "Kargo - Continuous Delivery Pipeline",
  height: "800px"
}
const kargoContent = (
    <EntityIFrameCard  {...iframeKargoProps} />
)

const iframeKubevirtManagerProps = {
  srcFromAnnotation: "kubevirt-manager",
  title: "KubeVirt-Manager",
  height: "800px"
}
const kubevirtManagerContent = (
  <EntityIFrameCard  {...iframeKubevirtManagerProps} />
)

const isKargoAvailable = (entity: Entity) => Boolean(entity.metadata.annotations?.["kargo-project"]);
const isKubevirtManagerAvailable = (entity: Entity) => Boolean(entity.metadata.annotations?.["kubevirt-manager"]);


const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={240} />
    </Grid>

    <EntitySwitch>
      <EntitySwitch.Case if={isHarborAvailable}>
        <Grid item>
          <HarborWidget />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isGithubInsightsAvailable}>
        <Grid item md={6} xs={12}>
          <EntityGithubInsightsComplianceCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={(e) => Boolean(isOverviewDashboardAvailable(e))}>
    <Grid item md={6}>
      {/* Grafana alert card start */}
      <EntityGrafanaDashboardsCard />
      {/* Grafana alert card end */}
    </Grid>
    </EntitySwitch.Case>
    </EntitySwitch>

      <EntitySwitch>
      <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
        <Grid item sm={6}>
          <EntityArgoCDOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

          <Grid item md={6} xs={12}>
            <AnnouncementsCard max={3} />
          </Grid>

    <Grid item md={6} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={12} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>

  <EntitySwitch>
      <EntitySwitch.Case if={isKubernetesAvailable}>
        <Grid item md={6}>
          <KyvernoOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

<EntitySwitch>
  <EntitySwitch.Case if={isVaultAvailable}>
    <Grid item md={6} xs={12}>
      <EntityVaultCard />
    </Grid>
  </EntitySwitch.Case>
</EntitySwitch>
  <Grid container spacing={3} alignItems="stretch">
    <EntitySwitch>
      <EntitySwitch.Case if={isJiraAvailable}>
        <Grid item md={12}>
          <EntityJiraOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route if={isTechDocsAvailable} path="/docs" title="Docs"> 
      {techdocsContent}
    </EntityLayout.Route>
    
    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>

    <EntityLayout.Route if={isKubecostAvailable} path="/kubecost" title="Kubecost">
      <BackstagePluginKubecostPage/>
    </EntityLayout.Route>

    <EntityLayout.Route if={isKargoAvailable} path="/kargo" title="kargo">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12} xs={12}>
        {kargoContent}
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route if={isKubernetesAvailable} path="/kubernetes" title="Kubernetes" >
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>

    <EntityLayout.Route if={isArgocdAvailable} path="/ci-cd" title="CD" >
      {cicdContent}
    </EntityLayout.Route> 

    <EntityLayout.Route if={isGithubActionsAvailable} path="/pull-requests" title="Pull Requests">
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="github-issues" title="GitHub Issues">
        <GithubIssuesPage />
      </EntityLayout.Route>
    <EntityLayout.Route if={isGithubInsightsAvailable} path="/code-insights" title="Github Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/grafana-dashboard" title="Grafana-Dashboard">
      {grafanaContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/kyverno-policy-reports" title="Kyverno Policy Reports">
      <KyvernoPolicyReportsTable />
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
    <Grid container spacing={3} alignItems="stretch">
      <Grid item md={6}>
        <EntityProvidedApisCard />
      </Grid>
      <Grid item md={6}>
        <EntityConsumedApisCard />
      </Grid>
    </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route if={isKubevirtManagerAvailable} path="/kube-virt-manager" title="kube-virt-manager">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12} xs={12}>
        {kubevirtManagerContent}
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/harbor" title="Harbor" if={isHarborAvailable}>
      <HarborPage />
    </EntityLayout.Route>


</EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route if={isTechDocsAvailable} path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route if={isTechDocsAvailable} path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('crossplane-claim')}>
    {crossplaneEntityPage}
  </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  <EntityLayout.Route path="/scaffolder" title="Crossplane Scaffolder">
      <EntityScaffolderContent
        templateGroupFilters={[
          {
            title: 'Crossplane Claims',
            filter: (entity, template) =>
              template.metadata?.labels?.forEntity === 'system' &&
              entity.spec?.type === 'kubernetes-namespace',
          },
        ]}
        buildInitialState={entity => ({
            xrNamespace: entity.metadata.name,
            clusters: [entity.metadata?.annotations?.['backstage.io/managed-by-location']?.split(": ")[1] ?? '']
          }
        )}
      />
  </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
